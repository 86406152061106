import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
import reactApp from '../../images/installing-spark/react/react-app.png';
import styleCheck from '../../images/installing-spark/react/style-check.png';
import buttonLoaded from '../../images/installing-spark/react/button-loaded.png';
import spinnergif from '../../images/installing-spark/html/html-spinner.gif';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Installing Spark in a React Project`}</h1>
    <p>{`This guide walks you through installing and using Spark on an existing
React app.`}</p>
    <p>{`For help with setting up the necessary development environment,
see the `}<a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/getting-started"
      }}>{`React App Getting Started Guide`}</a>{`.`}</p>
    <p>{`By the end of this guide, you’ll have Spark set up on your project and have access to all of our components.`}</p>
    <hr></hr>
    <p>{`Our `}<a href="https://github.com/sparkdesignsystem/spark-starter-react/" target="_blank">{`starter app examples`}</a>{` are available for reference:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-react/tree/kitchen-sink"
        }}>{`'kitchen-sink' branch`}</a>{` (Spark Installed with component examples.)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-react/tree/without-spark"
        }}>{`'without-spark' branch`}</a>{` (All of the prerequisites before Spark Installation.)`}</li>
    </ul>
    <h2>{`Installing Sass`}</h2>
    <a href="https://sass-lang.com/" target="_blank">Sass</a> is a tool that processes and compiles CSS. You’ll need to use Sass
to import Spark styles into your site.
    <ol>
      <li parentName="ol">{`Run this command to install Sass:`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`npm install node-sass --save-dev
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`App.css`}</inlineCode>{` to `}<inlineCode parentName="li">{`App.scss`}</inlineCode></li>
      <li parentName="ol">{`In `}<inlineCode parentName="li">{`App.js`}</inlineCode>{`, update the import line to point at the new filename.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import './App.scss'
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Create a new file in `}<inlineCode parentName="li">{`src`}</inlineCode>{` called `}<inlineCode parentName="li">{`styles.scss`}</inlineCode>{` and copy this CSS into it:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body {
  border: 50px solid pink;
}
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`In `}<inlineCode parentName="li">{`App.scss`}</inlineCode>{`, import this new file:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`@import './styles.scss';
`}</code></pre>
    <p>{`If your React server has stopped, restart it now and view your compiled
React site. You should see the new CSS you’ve written included in the
site, along with the original React CSS:`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={reactApp} alt="Screenshot of Create React App running in a browser." />
    </div>
    <h2>{`Install Spark Packages`}</h2>
    <p>{`Now we’ll use Sass to import all the Spark CSS.`}</p>
    <ol>
      <li parentName="ol">{`Install the Spark React package. It contains all of the Spark React components and Sass styles needed to build with Spark.`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`npm install @sparkdesignsystem/spark-react --save-dev
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Import the Spark Sass file into the main Sass file (e.g., `}<inlineCode parentName="li">{`App.scss`}</inlineCode>{`).`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`@import "../node_modules/@sparkdesignsystem/spark-styles/spark.scss";
`}</code></pre>
    <p>{`When you build and run your website, all the Spark CSS will now be bundled
into your output. You can verify this by inspecting your site’s DOM and
looking for the Spark CSS. There should be a `}<inlineCode parentName="p">{`<style>`}</inlineCode>{` tag in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{`
of your page that includes Spark’s styles.`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={styleCheck} alt="Screenshot of DOM with Spark Styles" />
    </div>
If Spark React is installed correctly, you should see these CSS rules
listed in your page’s code.
    <h2>{`Adding Your First Spark Component`}</h2>
    <p>{`Adding a Spinner Button can confirm you've installed Spark React packages correctly.`}</p>
    <p>{`This is just one example to implement functionality to get your project started.`}</p>
    <p>{`Rewrite `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` to into a `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html"
      }}>{`class component`}</a>{` and prepare `}<inlineCode parentName="p">{`SprkButton`}</inlineCode>{` to render a `}<inlineCode parentName="p">{`SprkSpinner`}</inlineCode>{` on click.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import './App.scss';
import { SprkButton, SprkSpinner } from '@sparkdesignsystem/spark-react';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isAppLoading: false};
  }

  render() {
    return (
      <SprkButton
        isSpinning={this.state.isAppLoading}
        onClick={() => { this.setState({ isAppLoading: true }) }}
      >
        {this.state.isAppLoading ? <SprkSpinner /> :'Start Spinning'}
      </SprkButton>
    );
  }
}

export default App;
`}</code></pre>
    <p>{`This code sample has:`}</p>
    <ul>
      <li parentName="ul">{`An import of the React package.`}</li>
      <li parentName="ul">{`A reference to your `}<inlineCode parentName="li">{`App.scss`}</inlineCode>{` file.`}</li>
      <li parentName="ul">{`An import of `}<inlineCode parentName="li">{`SprkButton`}</inlineCode>{` and `}<inlineCode parentName="li">{`SprkSpinner`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`State that will handle the status of `}<inlineCode parentName="li">{`isSpinning`}</inlineCode>{`, and render the `}<inlineCode parentName="li">{`SprkSpinner`}</inlineCode>{` when `}<inlineCode parentName="li">{`true`}</inlineCode>{` (for this example, we'll name it `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{`).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SprkButton`}</inlineCode>{` that sets `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{` to `}<inlineCode parentName="li">{`true`}</inlineCode>{` after clicking.`}</li>
      <li parentName="ul">{`Logic inside of `}<inlineCode parentName="li">{`SprkButton`}</inlineCode>{` that renders the `}<inlineCode parentName="li">{`SprkSpinner`}</inlineCode>{` according to `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{` state.`}</li>
    </ul>
    <p>{`Now, you should find a Spark Button that loads a Spinner on click!`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={spinnergif} alt="Screenshot of React App with Spark Button" />
    </div>
    <blockquote>
      <p parentName="blockquote">{`Learn more about `}<inlineCode parentName="p">{`SprkButton`}</inlineCode>{` and `}<inlineCode parentName="p">{`SprkSpinner`}</inlineCode>{` functionality in the `}<a parentName="p" {...{
          "href": "https://react.sparkdesignsystem.com/?path=/docs/components-button--primary"
        }}>{`Button Storybook documentation`}</a>{`.`}</p>
    </blockquote>
    <h2>{`Finding the code to other Spark Components`}</h2>
    <ol>
      <li parentName="ol">{`Go to the `}<a parentName="li" {...{
          "href": "https://react.sparkdesignsystem.com/"
        }}>{`Spark React Storybook`}</a>{`.`}</li>
      <li parentName="ol">{`Find the Component you need in the "Components" section.`}</li>
      <li parentName="ol">{`Navigate to the `}<em parentName="li">{`Docs`}</em>{` tab. It's typically at the top of screen next to `}<em parentName="li">{`Canvas`}</em>{`.`}</li>
      <li parentName="ol">{`Navigate down the page until you find your component variant.`}</li>
      <li parentName="ol">{`The `}<em parentName="li">{`Show code`}</em>{` button will toggle a code sample.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`These code samples represent the final `}<em parentName="p">{`rendered state`}</em>{` of the components. Just like with a `}<inlineCode parentName="p">{`SprkButton`}</inlineCode>{` with a spinner, some functionality requires additional engineering.`}</p>
    </blockquote>
    <p>{`The `}<em parentName="p">{`Docs`}</em>{` section of each page will give implementation details for each component.`}</p>
    <h2>{`Additional Topics`}</h2>
    <p>{`Check out these guides for more information on setting up Spark:`}</p>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-icons"
        }}>{`Icon Installation Guide`}</a>{`
for importing the Spark SVG Icon Set.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-fonts"
        }}>{`Font Installation Guide`}</a>{`
for instructions on using the Rocket Sans font.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      